import React from 'react';
import './Styles/Loader2.css';

function Payment() {
  return (
    <div class="spinner2"></div>
  );
}

export default Payment;
