import React from 'react';
import './Styles/Login.css';
import '../Components/Shared/Styles/Main.css';

import Footer from '../Components/Shared/Footer';
import Loader from '../Components/Shared/Loader1';

import {Animated} from "react-animated-css";
import ReCAPTCHA from "react-google-recaptcha";

const recaptchaRef = React.createRef();

class Login extends React.Component {

    state = {
        logginIn: false
    }

    login = async () => {
        
        var email = document.getElementById('loginEmail').value;
        var password = document.getElementById('loginPassword').value;

        this.setState({
            logginIn: true
        });
        
        var result = await fetch(`https://api.blockgate.io/v1/account/login/`, {
            credentials: "include",
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({email:email, password:password, captcha: recaptchaRef.current.getValue()})
        });
        var data = await result.json();

        if(data.redirect)
          return window.location.replace(data.redirect.url);
        
        
        this.setState({
          logginIn: false
        });
    }

  render() {
    return (
      <div className="contentWrapper">
        <div id="indexContainer">
            <div>
              <img src="/images/logo.svg" id="login-image" />
              <h2>BlockGate.io</h2>
            </div>

            <div id="indexSignupFormContainer">
                <input id="loginEmail" maxLength="128" placeholder="Email address" type="text"/> <br/>
                <input id="loginPassword" maxLength="128" placeholder="Password" type="password"/> <br/>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <ReCAPTCHA ref={recaptchaRef} sitekey="6LcXISMaAAAAALvgX7sQL9rbnyjvRHLrelwvwUnU"/>
                </div>

                <button disabled={this.state.logginIn} onClick={this.login}>
                  {
                    this.state.logginIn 
                      ? <Loader/>
                      : "Login"
                  }
                </button>
            </div>
        </div>
        <Footer/>
      </div>
    )
  }
}

export default Login;
