import React from 'react';
import ReactDOM from 'react-dom';

import './Styles/AddressDisplay.css';

class AddressDisplay extends React.Component {
    handleFocus = (event) => {
        event.target.select();
    }
    render() {

        return (
            <div id="addressContainer">
                <div id="qrAddressDisplay">
                    <img src={this.props.data.qrCodeUrl} alt="QR Code"/>
                </div>
                <p id="addressChoiseText">Scan QR Code or pay the following address</p>
                <input id="addressField" className="inputNoHighlight" onClick={this.handleFocus} value={this.props.data.address} type="text" readOnly/>
            </div>
        )
    }
}

export default AddressDisplay;