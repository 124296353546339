import React from 'react';
import './Styles/Loader1.css';

function Payment() {
  return (
    <div class="spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
    </div>
  );
}

export default Payment;
