import React from 'react';
import ReactDOM from 'react-dom';

import './Styles/Footer.css';

class Footer extends React.Component {
    
    showBetaMessage() {
        alert('Beta: in-development');
    }
    showBetaContact() {
        alert('Beta: contact blockgateio@gmail.com');
    }

    getPill(len) {
        return <div style={{display: 'inline-block', margin: 0, width:len+'px', height: '14px', background: '#bfbfbf', borderRadius: '5px', verticalAlign: 'top'}} />;
    }
    
    render() {

        var extraColumn;
        if(this.props.vendorData) {
            extraColumn =
                <div className="footer-item-container">
                    <span>Vendor</span>
                    <div>
                        <p>Name: {this.props.vendorData.displayName ?? this.getPill(75)}</p><br />
                        <p>Id: {this.props.vendorData.id ?? this.getPill(100)}</p>
                    </div>
                </div>
        } else {
            extraColumn = 
                <div className="footer-item-container">
                    <span>Links</span>
                    <div>
                        <a href="#" onClick={this.showBetaMessage}>API</a><br />
                        <a href="#" onClick={this.showBetaMessage}>Privacy Policy</a> <br />
                        <a href="#" onClick={this.showBetaMessage}>Terms Of Service</a><br />
                        <a href="#" onClick={this.showBetaMessage}>Frequently Asked Questions</a>
                    </div>
                </div>
        }

        return (
            <div id="footer">
                <div className="footer-item-container">
                    <span>Provider</span>
                    <div>
                        <p>Service is provided free of charge by BlockGate</p>
                    </div>
                </div>

                {extraColumn}

                <div className="footer-item-container">
                    <span>Contact</span>
                    <div>
                        <a href="#" onClick={this.showBetaContact}>Support</a><br />
                        <a href="#" onClick={this.showBetaContact}>Report Abuse</a>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer;