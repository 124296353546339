import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import Payment from './Payment'
import Index from './Index'
import Login from './Login'

class App extends React.Component {

  render() { 
    return (
        <Router>
            <Switch>
                <Route path="/payment/:vendorId/:transactionId" component={Payment}/>
                <Route path="/login" component={Login}/>
                <Route path="/" component={Index}/>
            </Switch>
        </Router>
    )
  }
}

export default App;
