import React from 'react';
import ReactDOM from 'react-dom';

import './Styles/ProductTitle.css';

class ProductTitle extends React.Component {
    render() {
        return (
            <div id="titleContainer">
                <h1>{this.props.data.name}</h1>
                <h2>{this.props.data.description}</h2>
            </div>
        )
    }
}

export default ProductTitle;