import React from 'react';
import ReactDOM from 'react-dom';


class TransactionCompleteDisplay extends React.Component {
    render() {
        return (
            <div style={{textAlign:'center'}}>
                <img style={{marginBottom: '5px'}} src="/icons/checkmark.svg"/><br/>
                <strong style={{color:'#62A1FF'}}>Payment received</strong>
                <p style={{margin: '0', padding: '0', color: '#C4C4C4', fontSize: '11px'}}>(Up to 4 confirmations could be required before the product is delivered)</p>
            </div>
        )
    }
}

export default TransactionCompleteDisplay;