import React from 'react';
import './Styles/Payment.css';
import '../Components/Shared/Styles/Main.css';

import Footer from '../Components/Shared/Footer';
import Title from '../Components/ProductTitle';
import Transaction from '../Components/TransactionDisplay';
import Error from '../Components/ErrorDisplay';
import Loader from '../Components/Shared/Loader2';
import { render } from '@testing-library/react';

import {Animated} from "react-animated-css";

var checkInterval;
class Payment extends React.Component {

  state = {
    loaded: false,
    data: {
      vendor: {
        loading: true
      }
    }
  }

  async checkTransaction() {
    try {
      var result = await fetch(`https://api.blockgate.io/v1/payment/${this.props.match.params.vendorId}/${this.props.match.params.transactionId}`);
      
      if(result.status != 200) {
        this.setError('not_found');
        return;
      }

      var data = await result.json();
      if(!data.state.completed && data.state.expired) {
        this.setError('expired');
        return;
      }
      
      this.setState({
        loaded: true,
        data: data
      });

      if(data.state.completed && data.state.btcIsConfirmed) {
        setTimeout(function() {
          for(var i = 0; i < data.href.length; i++) {
            if(data.href[i].name == 'success')
              window.location.href = data.href[i].url;
          }
        }, 4000);
      }

    } catch {
      this.setError('not_found');
    }
  }

  setError(name) {
    this.setState({
      loaded: true,
      error: {
        name: name
      }
    });
  }

  componentDidMount() {

    this.checkTransaction();
    checkInterval = setInterval(()=>{
      this.checkTransaction();
    }, 5000);
  }

  render() { 
    
    var formObject;
    if(this.state.error) {
      formObject = 
          <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true}>
            <Error error={{name:this.state.error.name}}/>
          </Animated>;
    } else {
      formObject = this.state.loaded ? 
      (
        <div>
          <Animated animationIn="fadeInDown" animationOut="fadeOut" isVisible={true}>
            <Title data={this.state.data.product}/>
          </Animated>
          <Animated animationIn="fadeInUp" animationOut="fadeOut" isVisible={true}>
            <Transaction data={this.state.data}/> 
          </Animated>
        </div>
      )
      :
      (
        <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true}>
          <Loader/>
        </Animated>
      );
    }

    return (
      <div className="contentWrapper">
        <div className="Payment">

          <div id="paymentTransactionContainer" className="centeredContentVerticalWrapper">
            { formObject }
          </div>

        </div>
        <Footer vendorData={this.state.data?.vendor}/>
      </div>
    )
  }
}

export default Payment;
