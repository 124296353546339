import React from 'react';
import ReactDOM from 'react-dom';

import './Styles/TransactionDisplay.css';

import Complete from './TransactionCompleteDisplay'
import Address from './AddressDisplay';
import Price from './PriceDisplay';
import Loader from './Shared/Loader1';

class TransactionDisplay extends React.Component {
    render() {

        var callbackUrl = this.props.data.state.completed ? this.props.data.href.find(x=>x.name == 'success').url : this.props.data.href.find(x=>x.name == 'cancel').url;

        return (
            <div id="transactionContainer">
                {
                    this.props.data.state.completed 
                    ? <Complete/>
                    : <Address data={this.props.data.address}/>
                }
                <Price data={this.props.data.price} state={this.props.data.state}/>
                
                { !this.props.data.state.completed && <Loader/> }
                
                <div id="backUrlContainer">
                    <a id="backUrl" href={callbackUrl}>
                        {
                            this.props.data.state.completed
                            ? "Go to " + this.props.data.vendor.displayName
                            : "Cancel, go back to " + this.props.data.vendor.displayName
                        }
                    </a>
                </div>
            </div>
        )
    }
}

export default TransactionDisplay;