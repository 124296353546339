import React from 'react';
import ReactDOM from 'react-dom';

import './Styles/PriceDisplay.css';
import Loader from '../Components/Shared/Loader1';

class PriceDisplay extends React.Component {

    selectOnClick = (id) => {
        window.getSelection().selectAllChildren(document.getElementById(id) );
    }

    render() {
        return (
            <div id="priceDisplay">
                {
                    (this.props.state.btcReceived < this.props.data.btc) 
                    ?
                        <div className="priceItemContainer">
                            <span>Price is <strong onClick={() => this.selectOnClick('btc-amount')}><strong id="btc-amount">{this.props.data.btc}</strong> BTC</strong></span>
                            <p>Make sure to pay the <strong>eaxct amount</strong></p>
                        </div>
                    : <div></div>
                }

                {
                    (this.props.state.btcReceived < this.props.data.btc) 
                    ? <div className="priceItemContainer">
                        <span>Received <strong className="textHighlight">
                                {
                                    this.props.state.btcReceived > 0 ? this.props.state.btcReceived : "0.00"
                                } </strong> BTC</span>
                        <p>This will update automatically</p>
                    </div>
                    :
                    <div className="priceItemContainer">
                        <span>Received <strong className="textHighlight">correct amount</strong>, waiting for bitcoin network confirmation</span>
                    </div>
                }

                {
                    (this.props.state.btcReceived >= this.props.data.btc)
                    ? <div style={{marginTop: 60}}>
                        <strong>Waiting for 4 confirmations on the blockchain</strong>
                        <p>Generally takes around 30 minutes - 1 hour depending on the paid fee</p>
                        <Loader/>
                        <p style={{marginTop: 20}}>You will be automatically redirected once the payment is confirmed</p>
                    </div>
                    : <div></div>
                }
            </div>
        )
    }
}

export default PriceDisplay;