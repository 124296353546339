import React from 'react';
import './Styles/Index.css';
import '../Components/Shared/Styles/Main.css';

import Footer from '../Components/Shared/Footer';

import {Animated} from "react-animated-css";

class Index extends React.Component {
  state = {
    email_valid: true,
    email_sent: false,
  }
  
  sendInterest = async () => {
    var element = document.getElementById('signupInterestEmail');
    var value = element.value;
    var emailPattern = /^([\w\.\-]+)@([\w\-]+)((\.(\w){2,3})+)$/i;

    if(value.match(emailPattern)) {

      await fetch('https://api.blockgate.io/v1/user/interest', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: value
        })
      });

      this.setState({
        email_valid: true,
        email_sent: true
      });
    } else {
      this.setState({
        email_valid: false,
        email_sent: false
      });
    }
  }

  render() {
    return (
      <div className="contentWrapper">
        <div id="indexContainer">
            <h1 id="indexTitle">BlockGate</h1>
            <h2 id="indexDescription">Accept Bitcoin payments using BlockGate.io</h2>

            <div id="indexSignupFormContainer">
                <p>
                  {
                    this.state.email_sent ?
                    <p style={{color:'#3989ff'}}>Email registered, we will notify you once the server is available!</p>
                    :
                    (
                      this.state.email_valid ?
                      "Sign up for early access here"
                      :
                      <p style={{color:'#ff6868'}}>Invalid email, please try again</p>
                    )
                  }
                </p>
                <input id="signupInterestEmail" maxLength="64" placeholder="Enter your email address" type="text"/> <button disabled={this.state.email_sent} onClick={this.sendInterest}>Get started</button>
            </div>
        </div>
        <Footer/>
      </div>
    )
  }
}

export default Index;
