import React from 'react';
import ReactDOM from 'react-dom';

import './Styles/ErrorDisplay.css';

class ResultDisplay extends React.Component {

    error_list = [
        { name: 'expired', short: 'Transaction Expired', text: 'Your transaction has expired due to not received the requested funds in the 4 hour time limit.', subText: 'If you have already sent the money the address will still be monitored for an additional 8 hours after expiration.'},
        { name: 'not_found', short: 'Invalid URL', text: 'We could not locate your requested transaction, please try again.', subText: 'Please ensure that you have entered the correct URL and that there are no mistakes.'}
    ]

    render() {

        var error = this.error_list.find(x=>x.name == this.props.error.name);

        return (
            <div id="resultContainer">
                <img src="/icons/close-24px.svg" id="resultIcon" width="160px"/>
                <div id="resultInnerContainer">
                    <div id="resultTextContainer">
                        <p id="resultText">{error.text}</p>
                        <p id="resultSubText">{error.subText}</p>
                    </div>
                    <span id="resultErrorCode"><strong>{error.short} </strong> (id: {this.props.error.name})</span>
                </div>
            </div>
        )
    }
}

export default ResultDisplay;